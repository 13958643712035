import React, { useState, useRef } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Linking,
  ScrollView,
} from "react-native";

const StyledPopup = ({ isVisible, onClose, link, disclaimer_text }) => {
  console.log("Popup render. isVisible:", isVisible, "link:", link);
  
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const scrollViewRef = useRef(null);

  if (!isVisible) return null;

  const handleOk = async () => {
      console.log("OK button pressed. Attempting to open link:", link);
      if (await Linking.canOpenURL(link)) {
        await Linking.openURL(link);
      }
      onClose();
  };

  const handleScroll = (event) => {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    const paddingToBottom = 20;
    const isCloseToBottom = layoutMeasurement.height + contentOffset.y >= 
      contentSize.height - paddingToBottom;
    
    if (isCloseToBottom && !isScrolledToEnd) {
      setIsScrolledToEnd(true);
    }
  };

  return (
    <View style={styles.overlay}>
      <View style={styles.popup}>
        <Text style={styles.title}>Disclaimer</Text>
        <ScrollView 
          ref={scrollViewRef}
          style={styles.scrollView}
          onScroll={handleScroll}
          scrollEventThrottle={400}
        >
          <Text style={styles.message}>
            {disclaimer_text}
          </Text>
        </ScrollView>
        <View style={styles.buttonContainer}>
          <TouchableOpacity style={styles.button} onPress={onClose}>
            <Text style={styles.buttonText}>Cancel</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.button, 
              styles.okButton,
            ]}
            onPress={handleOk}
            // disabled={!isScrolledToEnd}
          >
            <Text style={[
              styles.buttonText, 
              styles.okButtonText,
            ]}>
              Got it
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  popup: {
    backgroundColor: "#2D2B5A",
    borderRadius: 10,
    padding: 20,
    width: "80%",
    maxWidth: 300,
    maxHeight: "80%",
    transform: [{ translateY: "-20%" }], // Adjust this value as needed
  },
  title: {
    color: "white",
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  scrollView: {
    maxHeight: 150,
    marginBottom: 20,
  },
  message: {
    color: "white",
    fontSize: 14,
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    flex: 1,
    padding: 10,
    borderRadius: 5,
    backgroundColor: "#3D3B6A",
    marginHorizontal: 5,
  },
  buttonText: {
    color: "white",
    textAlign: "center",
    fontSize: 16,
  },
  okButton: {
    backgroundColor: "#6C63FF",
  },
  okButtonText: {
    fontWeight: "bold",
  },
  disabledButton: {
    backgroundColor: "#4A4A4A",
  },
  disabledButtonText: {
    color: "#A0A0A0",
  },
});

export default StyledPopup;