import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Linking,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  Image,
} from "react-native";
import { ScrollView } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import UAParser from "ua-parser-js";
import { LinearGradient } from "expo-linear-gradient";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import StyledPopup from "./Popup";

const screenHeight = Dimensions.get("window").height;

const ExternalRedirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

const GradientRectangle = ({ id }) => {
  const styles = StyleSheet.create({
    rectangle: {
      borderBottomLeftRadius: 25,
      borderBottomRightRadius: 25,
      height: 70,
      width: "100%",
    },
  });

  return (
    <>
      {id === "1782" || id==="1794" ? (
        <LinearGradient
        colors={["#85c456", "#2C5F2D"]} // Lighter green to much darker green
        style={styles.rectangle}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
        locations={[0, 1]}
        />
      ) : (
        <LinearGradient
          colors={["#2b2650", "#6943A1", "#8FAFFF"]}
          style={styles.rectangle}
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 1 }}
          locations={[0, 0.4, 1]}
        />
      )}
    </>
  );
};


const ItemComponent = ({ appData, action, device, handleItemPress, adTag }) => {
  const styles = StyleSheet.create({
    wrapper: {
      borderRadius: 20,
      backgroundColor: "#2b2650",
      alignSelf: "center",
      marginVertical: "1.6%",
      height: device?.includes("desktop") ? 75 : 95,
      width: "95%",
      padding: 10,
      alignContent: "center",
      justifyContent: "center",
    },
    container: {
      flexDirection: "row",
      alignItems: "center",
      padding: 10,
      alignContent: "center",
      justifyContent: "center",
      width: "100%",
    },
    logoContainer: {
      marginRight: "5%",
      height: device?.includes("desktop") ? 50 : 60,
      width: device?.includes("desktop") ? 50 : 60,
    },
    icon: {
      width: "100%",
      height: "100%",
      borderRadius: 10,
    },
    infoContainer: {
      flex: 1,
      width: "100%",
      flexDirection: "row",
      alignSelf: "flex-start",
    },
    textContainer: {
      flex: 1,
      marginRight: 10,
      width: "100%",
    },
    nameLabel: {
      fontSize: device?.includes("desktop") ? 12 : 14,
      color: "white",
      width: "95%",
      fontWeight: "bold",
    },
    description: {
      fontSize: device?.includes("desktop") ? 12 : 14,
      width: device?.includes("desktop") ? 160 : "90%",
      color: "white",
      marginTop: "2%",
      maxWidth: "100%",
    },
    actionButton: {
      flexDirection: "row",
      borderRadius: 10,
      borderWidth: 1,
      borderColor: "white",
      width: 30,
      height: 30,
      marginTop: 0,
      alignItems: "center",
      justifyContent: "center",
    },
  });

  const handlePress = () => {
    handleItemPress(
      {link: device?.includes("iOS") || device?.includes("desktop")
        ? appData.link_ios
        : appData.link_android,
      has_disclaimer: appData.has_disclaimer,
      disclaimer_text: appData.disclaimer_text
      }
    );
  };

  return (
    <TouchableOpacity style={styles.wrapper} onPress={handlePress}>
      <View style={styles.container}>
        <View style={styles.infoContainer}>
          <View style={styles.logoContainer}>
            <Image
              style={styles.icon}
              source={appData.iconUrl ? { uri: appData.iconUrl } : null}
              resizeMode="contain"
              onError={(error) => console.log("Error loading image:", error)}
            />
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.nameLabel}>{appData.name.toUpperCase()}</Text>
            <Text style={styles.description}>{action.instructions}</Text>
            <Text
              style={{
                marginTop: 5,
                textAlign: "left",
                fontSize: 8,
                color: "white",
                opacity: "50%",
              }}
            >
              {adTag}
            </Text>
          </View>
        </View>
        <View>
          <TouchableOpacity style={styles.actionButton} onPress={handlePress}>
            <AntDesign name="arrowright" size={24} color="white" />
          </TouchableOpacity>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const MainScreen = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [popupLink, setPopupLink] = useState("");
  const { somepath } = useParams();
  const [userData, setUserData] = useState(null);
  const [adTag, setAdTag] = useState("");
  const [platform, setPlatform] = useState("");
  const [osVersion, setOsVersion] = useState("");
  const [device, setDevice] = useState("");
  const [userAgentReady, setUserAgentReady] = useState(false);
  const [pulledData, setPulledData] = useState([]);
  const [disclaimerText, setDisclaimerText] = useState("");
  const handleItemPress = ({link, has_disclaimer, disclaimer_text}) => {
    if (has_disclaimer) {
    setPopupLink(link);
    setPopupVisible(true);
    setDisclaimerText(disclaimer_text);
    } else {
      if (Linking.canOpenURL(link)) {
        Linking.openURL(link);
      }
    }
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
    setPopupLink("");
  };

  useEffect(() => {
    const parser = new UAParser();
    const os = parser.getOS();
    parser.setUA(navigator.userAgent);
    const deviceType = parser.getDevice().type || "desktop";
    setPlatform(deviceType);
    setOsVersion(`${os.name} ${os.version}`);
    setDevice(os.name + "_" + deviceType);
    setUserAgentReady(true);
  }, []);

  useEffect(() => {
    if (userAgentReady) {
      axios
        .get(`https://gotap-demo-9c17cd917efc.herokuapp.com/api/offers/pull?id=${somepath}`)
        .then((response) => {
          setPulledData(response.data);
          return axios.get(
            `https://gotap-demo-9c17cd917efc.herokuapp.com/api/users/info?id=${somepath}`
          );
        })
        .then((userResponse) => {
          setUserData(userResponse.data);
          setAdTag(userResponse.data.ad_tag ? "#AD" : "");
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [userAgentReady, somepath]);

  const styles = StyleSheet.create({
    fullScreenContainer: {
      flex: 1,
      justifyContent: "center",
      alignContent: "center",
      alignSelf: "center",
      alignItems: "center",
    },
    iphoneContainer: {
      flex: 1,
      width: device?.includes("desktop") ? 350 : "100%",
      height: device?.includes("desktop") ? screenHeight - 60 : screenHeight,
      borderRadius: device?.includes("desktop") ? 30 : 0,
      backgroundColor: "#2b2650",
    },
    padding: {
      marginTop: 15,
      backgroundColor: "#363064",
      paddingTop: device?.includes("desktop") ? "3%" : "3%",
      paddingBottom: device?.includes("desktop") ? "3%" : "3%",
      borderRadius: 20,
    },
    overlayAvatar: {
      marginTop: -45,
      width: device?.includes("desktop") ? 90 : 110,
      height: device?.includes("desktop") ? 90 : 110,
      marginBottom: 10,
      borderRadius: 150 / 2,
      alignSelf: "center",
      zIndex: 1,
    },
  });

  if (!device || pulledData.length === 0 || !userData) {
    return (
      <ActivityIndicator
        style={{ flex: 1, alignSelf: "center" }}
        size="large"
        color="#9D3AFF"
      />
    );
  }

  return (
    <View style={styles.fullScreenContainer}>
      <style type="text/css">{`
        html, body {
          overscroll-behavior: none;
          bounce: false;
          background-color: ${device?.includes("desktop") ? "white" : "#2b2650"};
        }
      `}</style>
      <ScrollView
        alwaysBounceVertical={false}
        bouncesZoom={false}
        bounces={false}
        backgroundColor="#2b2650"
        style={styles.iphoneContainer}
      >
        <StyledPopup
          disclaimer_text={disclaimerText}
          isVisible={isPopupVisible}
          onClose={handlePopupClose}
          link={popupLink}
        />
        <GradientRectangle id={somepath}/>
        <Image
          style={styles.overlayAvatar}
          source={{ uri: userData?.image_url }}
        />
        <View>
          <Text
            style={{
              fontWeight: "700",
              alignSelf: "center",
              color: "white",
              fontSize: device?.includes("desktop") ? 18 : 24,
            }}
          >
            {userData?.name}
          </Text>
          <Text
            style={{
              fontWeight: "400",
              alignSelf: "center",
              color: "white",
              fontSize: 15,
              marginTop: 5,
            }}
          >
            {userData?.description}
          </Text>
        </View>

        <View style={styles.padding}>
          {pulledData.map((appData) =>
            appData.actions.map((action, actionIndex) => (
              <ItemComponent
                key={`${appData.id}-${actionIndex}`}
                appData={appData}
                action={action}
                device={device}
                handleItemPress={handleItemPress}
                adTag={adTag}
              />
            ))
          )}
        </View>
        <View
          style={{
            flex: 1,
            alignSelf: "center",
            alignContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              marginTop: device?.includes("desktop") ? 20 : 20,
              textAlign: "center",
              fontSize: 10,
              color: "white",
              opacity: "60%",
            }}
          >
            powered by
          </Text>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL("https://gotap.it");
            }}
          >
            <Image
              source={require("./assets/gotaplogo.png")}
              style={{
                marginTop: -5,
                width: 100,
                height: 45,
                opacity: "60%",
                resizeMode: "contain",
              }}
            />
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<MainScreen />} />
      <Route path="/:somepath" element={<MainScreen />} />
      </Routes>
    </Router>
  );
};

export default App;